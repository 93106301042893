import React from "react"
import { Link } from "gatsby"

import "./indexheader.scss"
import rafImg from "../../images/raf.jpg"
import fundedImg from "../../images/funded-with-ks.png"

const IndexHeader = () => (
  <header className="container landing-hero">
    <div className="landing-hero__half left">
      <img src={rafImg} alt="Specter No-Show Socks in shoes" />
    </div>
    <div className="landing-hero__half right">
      <h1 className="landing-hero__title">
        Functional socks for every occasion
      </h1>
      <p className="landing-hero__subtitle">
        Redesigned from the ground up. Our no-show socks are extremely
        comfortable, ultra durable, and incredibly low-profile.
      </p>
      <Link className="btn btn--arrow" to="/product/no-shows/">
        Buy Now
      </Link>
    </div>
    <div className="badge">
      <Link to="/about/">
        <img src={fundedImg} alt="Funded with Kickstarter" />
      </Link>
    </div>
  </header>
)

export { IndexHeader }
