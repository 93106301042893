import React from "react"
import { Link } from "gatsby"

import "./indexintro.scss"
import specterIconImg from "../../images/icon.png"
import specterCpImg from "../../images/specter-cp.jpg"

const IndexIntro = () => (
  <>
    <section className="container intro" id="intro">
      <div className="mission">
        <img
          className="mission__logo"
          src={specterIconImg}
          alt="Specter Logo"
        />
        <h2 className="mission__title">Specter No-Show Socks</h2>
        <p className="mission__body">
          We were tired of buying no-shows that were too thin, ripped too
          easily, and slipped off our heels. So, we decided to create the best
          no-show sock.
        </p>
      </div>
      <div className="design">
        <img
          className="design__img"
          src={specterCpImg}
          alt="Specter Socks On/Off Sneakers"
        />
        <div className="design__content">
          <h2 className="design__title">Your new everyday socks.</h2>
          <p className="design__body">
            Our socks are made with a special blend of bamboo, engineered to be
            ridiculously comfortable without compromising durability. Our
            functional design maximizes the life of each sock and allows you to
            wear them longer, making them an essential everyday sock.
          </p>
          <Link className="btn btn--arrow" to="/product/no-shows/">
            Learn More
          </Link>
        </div>
      </div>
    </section>
  </>
)

export { IndexIntro }
