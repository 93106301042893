import React from "react"

import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import { IndexHeader } from "../components/IndexHeader"
import { IndexIntro } from "../components/IndexIntro"
import { IndexValueProps } from "../components/IndexValueProps"

import "./index.scss"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Functional socks for every occasion" />
      <IndexHeader />
      <IndexIntro />
      <IndexValueProps />
    </Layout>
  )
}

export default IndexPage
