import React from "react"
import { Link } from "gatsby"

import "./indexvalueprops.scss"
import chImg from "../../images/ch.jpg"
import ubImg from "../../images/boost.jpg"

const IndexValueProps = () => (
  <section className="container value-props">
    <div className="image-grid">
      <div className="image-grid__col">
        <div className="image-grid__left">
          <h3>The only no-show socks you’ll ever need.</h3>
          <div className="image-grid__img">
            <img src={ubImg} alt="Specter Socks in Ultra Boosts" />
          </div>
        </div>
      </div>
      <div className="image-grid__col">
        <div className="image-grid__right">
          <div className="image-grid__img">
            <img src={chImg} alt="Specter Socks in Cole Haan" />
          </div>
          <p>
            Ideal for casual, professional, and athletic wear, Specter No-Shows
            are built to last and designed to be breathable, comfortable, and
            low-profile without slipping.
          </p>
          <Link className="btn btn--arrow" to="/product/no-shows/">
            Buy Now
          </Link>
        </div>
      </div>
    </div>
  </section>
)

export { IndexValueProps }
